<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">权限列表</span>
		</div>
		<div class="bg-white p-2 px-3 m-2">
			<div class="d-flex align-items-center margin-10-0">
				<el-button type="primary" size="mini" v-auth="'store.access/add'" @click="openDialog">添加权限</el-button>
			</div>
			<el-tree style="width: 100%;" :data="lists" :props="defaultProps" @node-click="handleNodeClick"
				default-expand-all :expand-on-click-node="false">
				<span class="custom-tree-node" slot-scope="{ node, data }">
					<span>
						<span>{{ data.name }}</span>
						<span v-if="data.url" class="badge ml-2">{{data.url}}</span>
					</span>
					<span>
						<el-button type="text" size="mini" @click="openDialog(data)" class="table-btn">修改</el-button>
						<el-button type="text" size="mini" @click="deleteItem(data)" class="table-last-btn"
							style="margin-left: 0;">删除</el-button>
					</span>
				</span>
			</el-tree>
			<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
				<!-- 添加/修改规则 -->
				<el-form ref="form" :model="form" label-width="100px">
					<el-form-item label="权限名称">
						<el-input v-model="form.name" placeholder="名称" size="mini" style="width: 25%;"></el-input>
					</el-form-item>
					<el-form-item label="上级权限">
						<el-select v-model="form.parent_id" placeholder="请选择上级权限" size="mini">
							<el-option label="顶级规则" :value="0"></el-option>
							<el-option v-for="(item,index) in accessList" :key="index" :label="item | tree"
								:value="item.access_id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="权限path">
						<el-input v-model="form.url" placeholder="path" size="mini" style="width: 25%;"></el-input>
					</el-form-item>
					<el-form-item label="排序">
						<el-input v-model="form.sort" placeholder="排序" size="mini" style="width: 25%;"></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible = false">取 消</el-button>
					<el-button type="primary" @click="submit">确 定</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['app', 'layout'],
		data() {
			return {
				lists: [],
				accessList: [],
				search: {
					s: 'store/store.access/index',
					page: 1,
				},
				defaultProps: {
					children: 'child',
					label: 'name'
				},
				dialogVisible: false,
				form: {
					name: '',
					parent_id: 0,
					url: '',
					sort: 100,
				},
				dialogTitle: '添加权限',
				dialogId: 0
			}
		},
		filters: {
			tree(item) {
				if (item.level == 0) {
					return item.name
				}
				let str = ''
				for (let i = 0; i < item.level; i++) {
					str += i == 0 ? '|--' : '--'
				}
				return str + ' ' + item.name;
			}
		},
		created() {
			this.getList();
		},
		methods: {
			// 打开弹出层
			openDialog(item = false) {
				console.log(item)
				// 初始化表单
				if (!item || typeof item === 'number') {
					this.form = {
						name: '',
						parent_id: '',
						url: '',
						sort: 100,
					}
					this.dialogId = 0
				} else {
					this.dialogTitle = '编辑权限'
					this.form = {
						name: item.name,
						parent_id: item.parent_id,
						url: item.url,
						sort: item.sort,
					}
					this.dialogId = item.access_id
				}
				// 显示弹出层
				this.dialogVisible = true
			},
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(_ => {
						done();
					})
					.catch(_ => {});
			},
			// 提交
			submit() {
				let params = {};
				let url = 'store/store.access/add';
				let data = {
					access: {}
				};
				data.access = this.form;
				if (this.dialogId > 0) {
					url = 'store/store.access/edit';
					data.access_id = this.dialogId
					params.access_id = this.dialogId
				}
				params.s = url;
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: url,
					},
					data: data,
					method: 'post',
				}).then(res => {
					this.$message({
						message: res.data.msg,
						type: 'success'
					});
					this.getList()
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
				this.dialogVisible = false
			},
			handleNodeClick(data) {
				console.log(data);
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data.tree
					this.accessList = res.data.data.lists
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 删除单个
			deleteItem(data) {
				this.$confirm('是否要删除该权限?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.layout.showLoading()
					this.axios({
						token: true,
						params: {
							s: 'store/store.access/delete',
							access_id: data.access_id,
						},
						method: 'post',
					}).then(res => {
						if (res.data.code == 1) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getList()
						}
						this.layout.hideLoading()
					}).catch(err => {
						this.layout.hideLoading()
					})
				})
			},
		},
	}
</script>

<style>
	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
	}
</style>
